<template>
  <div class="ratings"> 
    <span>
      <div class="rating-box">
        <span class="fa fa-stack main-rate-star" :style="stargap ? { 'margin-right' :stargap } : {}"><i @click="starClick(1)" @mouseover="hoverStart(0)" @mouseout="hoverStart(-1)" class="fa fa-star-o fa-stack-1x" :style="starsize ? { 'font-size': starsize } : {}" v-bind:class="[staractive[0] ? ' fa-star' : ' fa-star-o']"></i></span>
        <span class="fa fa-stack main-rate-star" :style="stargap ? { 'margin-right' :stargap } : {}"><i @click="starClick(2)" @mouseover="hoverStart(1)" @mouseout="hoverStart(-1)" class="fa fa-star-o fa-stack-1x" :style="starsize ? { 'font-size': starsize } : {}" v-bind:class="[staractive[1] ? ' fa-star' : ' fa-star-o']"></i></span>
        <span class="fa fa-stack main-rate-star" :style="stargap ? { 'margin-right' :stargap } : {}"><i @click="starClick(3)" @mouseover="hoverStart(2)" @mouseout="hoverStart(-1)" class="fa fa-star-o fa-stack-1x" :style="starsize ? { 'font-size': starsize } : {}" v-bind:class="[staractive[2] ? ' fa-star' : ' fa-star-o']"></i></span>
        <span class="fa fa-stack main-rate-star" :style="stargap ? { 'margin-right' :stargap } : {}"><i @click="starClick(4)" @mouseover="hoverStart(3)" @mouseout="hoverStart(-1)" class="fa fa-star-o fa-stack-1x" :style="starsize ? { 'font-size': starsize } : {}" v-bind:class="[staractive[3] ? ' fa-star' : ' fa-star-o']"></i></span>
        <span class="fa fa-stack main-rate-star" :style="stargap ? { 'margin-right' :stargap } : {}"><i @click="starClick(5)" @mouseover="hoverStart(4)" @mouseout="hoverStart(-1)" class="fa fa-star-o fa-stack-1x" :style="starsize ? { 'font-size': starsize } : {}" v-bind:class="[staractive[4] ? ' fa-star' : ' fa-star-o']"></i></span>
      </div>
    </span>	 
  </div>
</template>
<script>
export default {
    name: 'CommonRatingStars',
    components: {
    },
    props: { 
      rating: Number, 
      starsize: String,
      stargap: String,
      type: String
    },
    data(){
      return{ 
        staractive: [],
      }
    },
    created(){ 
      this.highligtStart(this.rating -1); 
    },
    watch:{
      rating: function(val) {
        this.highligtStart(this.rating -1); 
      }
    },
    methods:{ 
      hoverStart(val) {
        this.staractive = [];
        for (let index = 0; index < val+1; index++) {
          this.$set(this.staractive, index, true);          
        } 
        
        if(val === -1){
          this.highligtStart(this.rating -1); 
        }
      },
      highligtStart(val) {
        this.staractive = [];
        for (let index = 0; index < val+1; index++) {
          this.$set(this.staractive, index, true);          
        }
      },      
      starClick(val) {
        let type = ""
       
        this.$emit('clicked', val, this.type)
      }
    }
}
</script>
<style>
</style>

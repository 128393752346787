<template>
<div>
 <div class="row">
  <div class="col-sm-12">
   <!-- <B>Track Order</B> -->
     <!-- <p style="text-align: right; margin-top: -25px;"><B>Tracking No: {{ order.product_name }}</B></p> -->
  </div>
 </div>
 <div class="row">
    <div class="col-sm-12">
      <div class="container">
        <div v-if="payment_type === 1 || payment_type === 2">
        <ul class="progressbar" v-if="trackdata[0].order_line_status !== 0 && admin_approve === true">
          <li :class="[trackdata.length >= 0 ? 'active': '']">order Placed</li>
          <li :class="[trackdata.length >= 2 ? 'active': '']">Processing</li>
          <li :class="[trackdata.length >= 3 ? 'active': '']">Ready to Ship</li>
          <li :class="[trackdata.length >= 4 ? 'active': '']">Shipped</li>
          <li :class="[trackdata.length >= 5 ? 'active': '', statusClassCOD(4)  == 50 ? 'dfailed': '']"><span v-if="statusClassCOD(4) == 50" style="color: red;">Delivery Failed</span><span v-else>Delivered</span> </li>
        </ul>
        </div>

        <div v-if="payment_type === 3 || payment_type === 4">
        <ul class="progressbar" v-if="trackdata[0].order_line_status !== 0 && admin_approve === true">
          <li :class="[trackdata.length >= 2 ? 'active': '']">order Placed</li>
          <li :class="[trackdata.length >= 3 ? 'active': '']">Processing</li>
          <li :class="[trackdata.length >= 4 ? 'active': '']">Ready to Ship</li>
          <li :class="[trackdata.length >= 5 ? 'active': '']">Shipped</li>
          <li :class="[trackdata.length >= 6 ? 'active': '', statusClass(4)  == 50 ? 'dfailed': '']"><span v-if="statusClass(4) == 50" style="color: red;">Delivery Failed</span><span v-else>Delivered</span> </li>
        </ul>
        </div>

        <ul class="progressbar" v-if="trackdata[0].order_line_status !== 0 && admin_approve === false">
          <li :class="[trackdata.length >= 6 ? 'active': '']">order Placed</li>
          <li :class="[trackdata.length >= 2 ? 'active': '']">Processing</li>
          <li :class="[trackdata.length >= 3 ? 'active': '']">Ready to Ship</li>
          <li :class="[trackdata.length >= 4 ? 'active': '']">Shipped</li>
          <li :class="[trackdata.length >= 5 ? 'active': '', statusClass(4)  == 50 ? 'dfailed': '']"><span v-if="statusClass(4) == 50" style="color: red;">Delivery Failed</span><span v-else>Delivered</span> </li>
        </ul>


        <ul class="progressbar" v-if="trackdata[0].order_line_status === 0 && admin_approve === false">
          <li :class="[trackdata.length >= 0 ? 'deActive': '']">Cancelled</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Processing</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Ready to Ship</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Shipped</li>
          <li :class="[trackdata.length >= 10 ? 'active': '', statusClass(4)  == 50 ? 'dfailed': '']"><span v-if="statusClass(4) == 50" style="color: red;">Delivery Failed</span><span v-else>Delivered</span> </li>
        </ul>
      
        <ul class="progressbar" v-if="trackdata[0].order_line_status === 0 && admin_approve === true">
          <li :class="[trackdata.length >= 0 ? 'deActive': '']">Cancelled</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Processing</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Ready to Ship</li>
          <li :class="[trackdata.length >= 10 ? 'active': '']">Shipped</li>
          <li :class="[trackdata.length >= 10 ? 'active': '', statusClass(4)  == 50 ? 'dfailed': '']"><span v-if="statusClass(4) == 50" style="color: red;">Delivery Failed</span><span v-else>Delivered</span> </li>
        </ul>

      </div>
      <div class="container">
        <ul>
        <div v-if="payment_type === 1 || payment_type === 2">
          <li style="list-style: disc; padding: 10px;" v-for="(item, index) in trackdata" :key="index"  v-if="trackdata[0].order_line_status !== 0 && admin_approve === true">
            <p style="font-size: 14px; font-weight: bold; margin-bottom: 0px;">{{fetchOrderStatusText(item.order_line_status) }}</p> 
            {{item.created_date.replace(/T/, ' ').replace(/\..+/, ' ').replace(/\+05:30/, ' ')}}
          </li>
        </div>

        <div v-if="payment_type === 3 || payment_type === 4">
          <li style="list-style: disc; padding: 10px;" v-for="(item, index) in trackdata" :key="index"  v-if="index+1 !== trackdata.length && trackdata[0].order_line_status !== 0 && admin_approve === true">
            <p style="font-size: 14px; font-weight: bold; margin-bottom: 0px;">{{fetchOrderStatusText(item.order_line_status) }}</p> 
            {{item.created_date.replace(/T/, ' ').replace(/\..+/, ' ').replace(/\+05:30/, ' ')}}
          </li>

        </div>

          <li style="list-style: disc; padding: 10px;" v-if="trackdata[0].order_line_status === 0">
            <p style="font-size: 14px; font-weight: bold; margin-bottom: 0px;">{{fetchOrderStatusText(trackdata[0].order_line_status) }}</p> 
            {{trackdata[0].created_date.replace(/T/, ' ').replace(/\..+/, ' ').replace(/\+05:30/, ' ')}}
          </li>

        </ul>
      </div>
      
    </div>

  </div>
</div> 
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
export default {
  mixins: [mixinOrder],
  props: {
    order: Object
  },
  data(){
    return {
      history: [],
      trackdata: [],
      tracktimeline: [],
      payment_type: '',
      admin_approve: ''
    }
  },
  created() {
    this.getStatusHistoryDetails();
  },
  mounted() {
    this.getStatusHistoryDetails();
  },
  watch: {
    order(val) {
        this.getStatusHistoryDetails();
    }
  },
  methods: {
    getStatusHistoryDetails: async function () {
      if (this.order._id) {
         try {
            this.trackdata = [];
            this.history = [];
            this.tracktimeline = [];
            let id = this.order._id;
            let responce = await this.getStatusHistory(id);
            this.payment_type = this.order.payment_type,
            this.admin_approve = this.order.admin_approve,

            Object.assign(this.tracktimeline, responce) 
            // this.tracktimeline = responce;
            this.trackdata = responce.reverse();
            this.history = responce.map(obj=>{
              obj.created_date = moment(obj.created_date).format()
              let dats = obj.created_date.split("T");
              obj.date = dats[0];
              return obj;
            });
          } catch (error) {
            throw(error); 
          }
      }
    },
    fetchOrderStatusText (status) {
      switch (status) {
        case 0:
          return "Order Cancelled."
          break;

        case 1:
          return "Order Placed."
          break;
  
        case 10:
          return "Order Processed."       
          break;
  
        case 20:
          return "Order Ready to Ship."
          break;
  
        case 30:
          return "Order Shipped."
          break;
  
        case 40:
          return "Order Delivered."
          break;

        case 50:
          return "Order Delivery Failed."
          break;

        case 60:
          return "Return to Store."
          break;

        case 70:
          return "accept return"
          break;

        case 80:
          return "In Dispute."
          break;
        
        case 90:
          return "Dispute Closed."
          break;

        default:
          break;
      }
    },
    statusClass(index){
      switch (index) {
        case 4:

          if(this.tracktimeline[5]){
            return this.tracktimeline[5].order_line_status
          } else {
            return '';
          }
          
          break;
      
        default:
          break;
      }
    },
    statusClassCOD(index){
      switch (index) {
        case 4:

          if(this.tracktimeline[4]){
            return this.tracktimeline[4].order_line_status
          } else {
            return '';
          }
          
          break;
      
        default:
          break;
      }
    }
  }
}
</script>
<style scoped>

  .well {
    background-color: transparent !important;
  }
  .progressbar {
      counter-reset: step;
  }
  .progressbar li {
      list-style-type: none;
      width: 20%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;
  }
  .progressbar li:before {
      width: 30px;
      height: 30px;
      /* content: counter(step); */
      content: no-close-quote;
      counter-increment: step;
      line-height: 30px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
  }
  .progressbar li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: -50%;
      z-index: -1;
  }
  .progressbar li:first-child:after {
      content: none;
  }
  .progressbar li.active {
      color: rgb(77, 161, 48);
  }

  .progressbar li.deActive {
      color: red;
  }

  .progressbar li.deActive:before {
      border-color: red;
      /* background-color: rgb(77, 161, 48); */
      /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
      background-image: url('https://www.shareicon.net/data/256x256/2015/09/15/101562_incorrect_512x512.png');
      background-size: contain;
  }

  .progressbar li.active:before {
      border-color: rgb(77, 161, 48);
      /* background-color: rgb(77, 161, 48); */
      /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
      background-image: url('/assets/image/sample_product_images/confirm1.png');
      background-size: contain;
  }
  .progressbar li.active + li:after {
      background-color: rgb(77, 161, 48);
  }

  .progressbar li.dfailed:before {
      border-color: red;
      background-color: red !important;
      /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
      background-image: url('https://www.shareicon.net/data/256x256/2015/09/15/101562_incorrect_512x512.png');
      background-size: contain;
  }
  @media screen and (max-width: 768px) {
    .progressbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .progressbar li {
      min-width: 107px;
  }
  }

</style>


<template>
  <div v-if="packageList.length > 0" id="orderListBuyer" class="buyerodrlist">
    <div v-for="(pack, index1) in packageList" :key="index1" :style="index1 === 0 ? ' border-top: 15px solid rgb(246, 246, 246)':''"  style="border: 0px solid #ddd; padding: 10px; background: white; border-bottom: 15px solid rgb(246, 246, 246);" class="row">
    <!-- <div class="row"> -->
      <div class="col-md-12 order-list-top">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-sm-12 class-spce-btn"
            style="display: flex; justify-content: space-between;"
          >
            <div>
              <h3 class="title">Package {{index1 + 1}}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="margin-bottom:10px;">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4 class="title">
              ORDER ID :
              {{
              order.order_env_number != undefined
              ? " " + order.order_env_number
              : ""
              }}
            </h4>
          </div>
        </div>
      </div>

      <div v-if="pack[1][0].order_line_status === 1" class="col-md-12" style="margin-bottom:10px;">
        <div class="row">
          <div style="vertical-align: middle;" class="col-lg-12 col-md-12 col-sm-12">
            <button
              type="submit"
              size="md"
              variant="primary"
              class="ml-1 mr-1 pull-left"
              @click="selectAccount(pack[0],pack[0])"
              >{{selectAll === pack[0] ?"Remove All":"Select All"}}
            </button>
            <button
              type="button"
              class="btn btn-sm btn btn-Remove"
              @click="removeOrderItems()"
              :disabled="selectpackId !== pack[0]"
            >Cancel Items</button>
          </div>
        </div>
      </div>

      <div id="table-content" class="col-md-12 col-sm-12">
        <div id="table-content" class="col-sm-12 buyerodr--singleview" style="padding-right: 0px; padding-left: 0px;">
          <table class="table table-striped table-border-out">
            <thead>
              <tr>
                <th v-if="pack[1][0].order_line_status === 1" scope="col" class="head-table"> select Cancel Items</th>
                <th scope="col" class="head-table"></th>
                <th scope="col" class="head-table">Order</th>
                <th scope="col" class="head-table">Status</th>
                <th scope="col" class="head-table">Amount</th>
                <th scope="col" class="head-table">QTY</th>
                <th scope="col" class="head-table">Coupon Discount</th>
                <th scope="col" class="head-table">Voucher Discount</th>
                <th scope="col" class="head-table">Sub Total</th>
                <th scope="col" class="head-table">PAYMENT METHOD</th>
                <th scope="col" class="head-table">Action</th>
              </tr>
            </thead>
            <tbody>
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <!-- <tr>
                <td
                  colspan="7"
                  class="text-center col-md-12"
                  v-if="blockLoading == false && isEmpty(order)"
                >No Records Found!</td>
              </tr> -->
              <tr  v-for="(item, index) in pack[1]" :key="index">
                <td v-if="pack[1][0].order_line_status === 1" style="vertical-align: middle;" class="text-center col-md-1">
                  <input type="checkbox"
                    v-if="item.order_line_status === 1" 
                    v-model="selected"
                    :value="item._id" 
                    :id="item._id" 
                    class="ml-3"
                    @change="selectAccount(item,pack[0])"
                  >
                </td>
                <td style="vertical-align: middle;" class="text-center col-md-1">
                  <a>
                    <img
                      style="width:70px; height: 70px;"
                      :src="item.simple_product_id.images[0].image"
                      class="img-thumbnail"
                    />
                  </a>
                </td>
                <td class="text-left col-md-3" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12" style=" ">
                      <a>
                        <b>
                          {{
                          item.simple_product_id.product_name
                          }}
                        </b>
                      </a>
                      <br />
                      <div>Date : {{ item.date }}</div>
                      <div>Time : {{ createdTime }}</div>
                    </div>
                  </div>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <span
                      class="badge"
                      style="border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      :style="
                        item.order_line_status === 0
                          ? 'background: rgb(225, 83, 55);'
                          : item.order_line_status === 1
                          ? 'background: rgb(254, 205, 54);'
                          : item.order_line_status === 10
                          ? 'background: rgb(254, 205, 54); color: white;'
                          : item.order_line_status === 20
                          ? 'background: rgb(0, 91, 170); color: white;'
                          : item.order_line_status === 30
                          ? 'background: rgb(163, 43, 185); color: white;'
                          : item.order_line_status === 40
                          ? 'background: rgb(77, 161, 48); color: white;'
                          : item.order_line_status === 41
                          ? 'background: #17c017; color: white;'
                          : item.order_line_status === 50
                          ? 'background: rgb(225, 83, 55); color: white;'
                          : item.order_line_status === 60
                          ? 'background: rgb(0, 91, 170); color: white;'
                          : 'background: rgb(0, 91, 170); color: white;'
                      "
                    >
                    {{
                      item.order_line_status === 0
                      ? "Cancelled"
                      : item.order_line_status === 1 && item.admin_approve === true && item.is_payment === true
                      ? "Pending"
                      : item.order_line_status === 1 && item.admin_approve === false && item.is_payment === false
                      ? "Payment Pending"
                      : item.order_line_status === 10
                      ? "Processing"
                      : item.order_line_status === 20
                      ? "Ready to ship"
                      : item.order_line_status === 30
                      ? "Shipped"
                      : item.order_line_status === 40
                      ? "Delivered"
                      : item.order_line_status === 41
                      ? "Delivery Confimed"
                      : item.order_line_status === 50
                      ? "Delivery Failed"
                      : item.order_line_status === 60
                      ? "Return"
                      : item.order_line_status === 80
                      ? "In Dispute"
                      : item.order_line_status === 90
                      ? "Dispute Closed"
                      : "Error"
                    }}
                  </span>
                  <button style="font-size: inherit;" v-if="item.order_cancel_reason_text && item.order_line_status === 0" type="button" class="btn btn-link"  @click="haddleFailedText(0, item.order_cancel_reason_text)">View Reason</button>
                  <button style="font-size: inherit;" v-if="item.delivery_failed_text && item.order_line_status === 50" type="button" class="btn btn-link"  @click="haddleFailedText(50, item.delivery_failed_text)">View Reason</button>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <B>
                    {{
                    item.unit_price != undefined ? "Rs. " + item.unit_price : ""
                    }}
                  </B>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <B>{{ item.qty != undefined ? "x" + item.qty : "" }}</B>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <B>{{ item.coupon_discount_amount != undefined ? "Rs. - " + item.coupon_discount_amount : "" }}</B>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <B>{{ item.voucher_discount != undefined ? "Rs. - " + item.voucher_discount : "" }}</B>
                </td>
                <td class="text-left col-md-1" style="vertical-align: middle; text-align: center;">
                  <B>
                    {{
                    item.qty != undefined
                    ? "Rs. " + (item.unit_price * item.qty - item.coupon_discount_amount - item.voucher_discount)
                    : ""
                    }}
                  </B>
                </td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700; text-align: center;"
                  v-if="item.payment_type === 1"
                >Supiri Pola Credit</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700; text-align: center;"
                  v-if="item.payment_type === 2"
                >Cash On Delivery</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700; text-align: center;"
                  v-if="item.payment_type === 3"
                >Bank Transfer</td>
                <td
                  class="text-left col-md-2"
                  style="vertical-align: middle; font-weight: 700; text-align: center;"
                  v-if="item.payment_type === 4"
                >Card Payment</td>
                <td class="text-left col-md-4 action-btn-set" style="vertical-align: middle; text-align: center;">
                  
                  <button
                    type="button"
                    title
                    class="btn btn-track btn-sm buyerprof-sbtn"
                    data-original-title="View"
                    @click="trackOrder(item)"
                  >Track</button>&nbsp;

                   <!-- v-if="item.order_line_status === 40" -->
                  <!-- <button
                    v-if="item.order_line_status === 40 && disableDsipue !== true"
                    type="button"
                    class="btn btn-sm btn btn-dispute"
                    data-toggle="modal"
                    data-target="#disputeModalCenter"
                    @click="haddlePassObj(item)"
                  >Open Dispute</button>

                  <br/>
                  <div style="color: red;" v-if="disableDsipue !== true && item.order_line_status && item.order_line_status === 40">
                  End IN:- {{ calcDisputeTime(countTime, item.updated_date) }}
                  </div> -->
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="10">
                  <span><b>Sold by:</b> &nbsp;&nbsp; {{ pack[1][0].simple_product_id.product_id.store.store_name }} &nbsp;&nbsp; <b>& Delivered By: </b> {{ pack[1][0].delivery_partner.company_name }}</span>
                  <!-- <span>Package Weight: {{ orderListWeight('all')}} Kg</span> -->
                </td>
              </tr>
            </tfoot>
          </table>
          <!-- <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <!-- <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            Merchant Name:- {{ order.simple_product_id.product_id.store.store_name }}
            Deliver By:- {{ order.delivery_partner.company_name }}
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="col-lg-5 col-md-5 col-sm-12 align-line-spc-btn">
                <div>
                  <B>Order ID</B>
                </div>
                <div>:</div>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-12">
                <span class="font-detail">
                  {{
                  order.order_env_number != undefined
                  ? " " + order.order_env_number
                  : ""
                  }}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="col-lg-4 col-md-4 col-sm-12 align-line-spc-btn">
                <div>
                  <B>Name</B>
                </div>
                <div>:</div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <span class="font-detail">
                  {{
                  order.order_id != undefined
                  ? order.order_id.shipping_name != undefined
                  ? order.order_id.shipping_name
                  : ""
                  : ""
                  }}
                </span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="col-lg-5 col-md-5 col-sm-12 align-line-spc-btn">
                <div>
                  <B>Contact</B>
                </div>
                <div>:</div>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-12">
                <span class="font-detail">
                  {{
                  order.order_id != undefined
                  ? order.order_id.shipping_mobile != undefined
                  ? order.order_id.shipping_mobile
                  : ""
                  : ""
                  }}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="col-lg-4 col-md-4 col-sm-12 align-line-spc-btn">
                <div>
                  <B>Home Number</B>
                </div>
                <div>:</div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <span
                  v-if="order.order_id.user_id.secondary_mobile"
                  class="font-detail"
                >{{ order.order_id.user_id.secondary_mobile }}</span>
                <span v-if="!order.order_id.user_id.secondary_mobile" class="font-detail">Not Available</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="col-lg-4 col-md-4 col-sm-12 align-line-spc-btn">
                <div>
                  <B>Address</B>
                </div>
                <div>:</div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <span class="font-detail">
                  {{
                  order.order_id.address_id != undefined
                  ? order.order_id.address_id.address_line_1 != undefined
                  ? order.order_id.address_id.address_line_1 + ","
                  : ""
                  : ""
                  }}
                  {{
                  order.order_id.address_id != undefined
                  ? order.order_id.address_id.address_line_2 != undefined
                  ? order.order_id.address_id.address_line_2 + ","
                  : ""
                  : ""
                  }}
                  {{
                  order.order_id.address_id != undefined
                  ? order.order_id.address_id.city != undefined
                  ? order.order_id.address_id.city.city_name != undefined
                  ? order.order_id.address_id.city.city_name + ","
                  : ""
                  : ""
                  : ""
                  }}
                  {{
                  order.order_id.address_id != undefined
                  ? order.order_id.address_id.country != undefined
                  ? order.order_id.address_id.country.country_name != undefined
                  ? order.order_id.address_id.country.country_name
                  : ""
                  : ""
                  : ""
                  }}
                </span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-12">
        <h4
          class="title"
          style="    margin-top: 20px; margin-bottom: 20px;"
        >Delivery Partner Details</h4>
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
          <table
            id="example"
            class="table table-striped table-border-out"
            style="overflow-x: auto;"
          >
            <thead>
              <tr>
                <th scope="col" class="head-table" style="text-align: center;">Company Name</th>
                <th scope="col" class="head-table" style="text-align: center;">Charge</th>
                <th scope="col" class="head-table" style="text-align: center;">Email Address</th>
                <th scope="col" class="head-table" style="text-align: center;">Mobile Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.delivery_partner.company_name }}</td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >Rs. {{ order.delivery_charge }}</td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.delivery_partner.email }}</td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.delivery_partner.mobile }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-md-12">
        <h4 class="title" style="    margin-top: 20px; margin-bottom: 20px;">Outlet Details</h4>
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
          <table
            id="example"
            class="table table-striped table-border-out"
            style="overflow-x: auto;"
          >
            <thead>
              <tr>
                <th scope="col" class="head-table" style="text-align: center;">Outlet Name</th>
                <th scope="col" class="head-table" style="text-align: center;">Email Address</th>
                <th scope="col" class="head-table" style="text-align: center;">Mobile Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.simple_product_id.product_id.store.store_name }}</td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.simple_product_id.product_id.store.email }}</td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >{{ order.simple_product_id.product_id.store.phone }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    <div class="bottom-form1">

      <div class="row" style="margin-left: 0px;margin-right: 0px;">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
          style="padding-left: 0px;padding-right: 0px; overflow-x: auto;"
        >
          <table class="table buyertotal-value--tbl">
            <thead>
              <tr>
                <!-- <th>Items In Cart</th> -->
                <th>Sub Total</th>
                <th>Shipping and Handling Fee</th>
                <th>Voucher/Coupon Discount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td style="text-align: center;">
                  <h4>{{ tableItems.length }}</h4>
                </td> -->
                <td style="text-align: center;">
                  <h4>Rs. {{ subtotal }}</h4>
                  <!-- <h4>Rs. {{ order.item_total_price }}</h4> -->
                </td>
                <td style="text-align: center;">
                  <h4>Rs. {{ shippingFee }}</h4>
                  <!-- <h4>Rs. {{ order.delivery_charge }}</h4> -->
                </td>
                <td style="text-align: center;">
                  <h4>Rs. - {{ (order.order_id.voucher_discount_amount + couponDiscount).toFixed(2) }}</h4>
                </td>
                <td style="text-align: center;">
                  <h4>Rs. {{ (subtotal + shippingFee - order.order_id.voucher_discount_amount - couponDiscount).toFixed(2) }}</h4>
                  <!-- <h4>Rs. {{ (order.total_charge).toFixed(2) }}</h4> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Modal rate oder-->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="ratingModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Rate Your Order</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <h4>Seller Communication</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingSellCom)"
                  @clicked="onClickRatingSellCom"
                />
                <h4>Shipping Time</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingShipTime)"
                  @clicked="onClickRatingShipTime"
                />
                <h4>Product Quality</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingProdQual)"
                  @clicked="onClickRatingProdQual"
                />
              </div>
              <div class="col-md-6">
                <label>Comment</label>
                <textarea
                  v-model="ratingComment"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  style="height: auto !important;"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn btn-primary"
                @click="submitRating()"
                style="background: rgb(255, 211, 56);
              border-color: rgb(255, 211, 56); "
              >Submit Rating</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal dispute -->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="disputeModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Submit Dispute</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row box-modal">
              <div class="col-md-12 order-list-top" style="margin-left: -30px;">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
       
                      <div class="col-lg-4 col-md-4 col-sm-12 align-line-spc-btn">
                        <div>
                          <B>Order ID</B>
                        </div>
                        <div>:</div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-12">
                        <span class="font-detail">
                          {{
                          order.order_env_number != undefined
                          ? order.order_env_number
                          : ""
                          }}
                        </span>
                      </div>
                  
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
              
                      <div class="col-lg-4 col-md-4 col-sm-12 align-line-spc-btn">
                        <div>
                          <B>Product Name</B>
                        </div>
                        <div>:</div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-12">
                        <span class="font-detail">
                          {{
                          disputeProductName != undefined
                          ? disputeProductName != undefined
                          ? disputeProductName
                          : ""
                          : ""
                          }}
                        </span>
                      </div>
                 
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Refund Method</label>
                <div class="form-group" style="width: 100%;">
                  <select class="form-control select-arrow" :disabled="this.order.payment_type === 4" v-model="disputeObj.refund_method">
                      <option v-for="(item, val) in disputeRefundMethod" :key="val" :value="item._id" :disabled="item.active">{{ item.method}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="disputeObj.refund_method === 1 && order.payment_type !== 4" class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Bank</label>
                <div class="form-group" style="width: 100%;">
                  <!-- <input class="dispute-text-input" type="text" v-model="disputeObj.bank"> -->
                  <select class="form-control select-arrow"  @change="changeBank($event.target.value)" v-model="disputeObj.bank">
                    <option value=""> --- Please Select --- </option>
                    <option v-for="(item, val) in banks" :key="val" :value="item.name">{{ item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="disputeObj.refund_method === 1 && order.payment_type !== 4" class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Branch</label>
                <div class="form-group" style="width: 100%;">
                  <!-- <input class="dispute-text-input" type="text" v-model="disputeObj.branch"> -->
                  <select class="form-control select-arrow" :disabled="branch.length === 0"  @change="changeBranch($event.target.value)" v-model="disputeObj.branch">
                    <option value=""> --- Please Select --- </option>
                    <option v-for="(item, val) in branch" :key="val" :value="item.name">{{ item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="disputeObj.refund_method === 1 && order.payment_type !== 4" class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Account Number</label>
                <div class="form-group" style="width: 100%;">
                  <input class="dispute-text-input" type="text" v-model="disputeObj.account_number">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Dispute Main Reason</label>
                <div class="form-group" style="width: 100%;">
                  <select class="form-control select-arrow" @change="changeDisputeReason($event.target.value)" v-model="disputeObj.dispute_reason" >
                      <option value=""> --- Please Select --- </option>
                      <option v-for="(item, val) in disputeMainOptions" :key="val" :value="item._id">{{ item.reason}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>Dispute Sub Reason</label>
                <div class="form-group" style="width: 100%;">
                  <select class="form-control select-arrow" v-model="disputeObj.sub_dispute_reason" :disabled="!isDisabled">
                      <option value=""> --- Please Select --- </option>
                      <option v-for="(item, val) in disputeSubOptions" :key="val" :value="item._id">{{ item.reason}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label style="margin-right:20px;">Please add your dispute reason</label>
                <textarea
                  v-model="disputeDescription"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  style="height: auto !important;"
                  maxlength="150"
                ></textarea>
                <div class="char-comment">
                  <B>{{ commentLength + " Characters left" }}</B>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-5" v-for="(item, index) in image" :key="index">
                <div class="row col-md-12">
                  <img data-index="1"  :src="item"  class="img thumbnail">
                </div> 
                <div class="row">
                  <button class="btn btn-sm btn-danger" @click="clearImage(index)"><li class="fa fa-close"></li></button>
                </div>
              </div>
              <div class="col-md-5" v-if="isLoading"> 
                <div class="row col-md-12">
                  <img data-index="1"  src="assets/image/product/loading.gif"  class="img thumbnail">
                </div> 
              </div>
              <div class="col-md-12">
                  <div class="form-group">        
                    <label>Upload Image</label>    
                    <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile">
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                type="button"
                class="btn btn-primary"
                @click="submitDispute()"
                :disabled="!formIsValid"
                style="background: rgb(255, 211, 56);
              border-color: rgb(255, 211, 56); "
              >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal trackorder -->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="trackModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">Track order</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
                <div class="row">
                  <div v-if="showTrack" class="col-lg-12 col-md-12 col-sm-12">
                    <TrackOrder :order="selectedObj" />
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="display: flex;">
            <div style="text-align: left;">
              <button
                  v-if="selectedObj.order_line_status === 40 && this.disableDsipue == false"
                  type="button"
                  class="btn btn-sm btn btn-dispute"
                  data-toggle="modal"
                  data-target="#disputeModalCenter"
                  @click="haddlePassObj(selectedObj)"
                >Open Dispute</button>
              <div style="color: red;" v-if="this.disableDsipue == false  && selectedObj.order_line_status === 40">
              Resolution Required within:- {{ calcDisputeTime(countTime, selectedObj.updated_date) }}
              </div>
            </div>
            <div style="margin-left: auto;">
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DFail view-->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="failedTextModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle" v-if="failTextName">{{ failTextName }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 order-list-top">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <span v-if="failText" class="badge" style="background: white; color: rgb(225, 83, 55);">{{ failText }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-secondary buyerprof-sbtn" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End-->
  </div>
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
import TrackOrder from "./TrackOrder";
import mixinProduct from "@/mixins/buyer/APIProduct";
import CommonRatingStars from "@/components/common/commonRatingStars.vue";
export default {
  mixins: [mixinOrder, mixinProduct],
  components: {
    TrackOrder,
    CommonRatingStars
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Order Details",
          active: true
        }
      ],
      specialCondition: 0,
      showTrack: false,
      enableTrackOrder: false,
      showOrderCancel: false,
      packageList: [],
      tableItems: [],
      oderLines: [],
      disputeRefundMethod: [
        { 
          _id: 1,
          method: "Bank Refund",
          active: false
        },
        { 
          _id: 2,
          method: "Voucher",
          active: false
        },
        { 
          _id: 3,
          method: "Credit Card Reversal",
          active: true
        }
      ],
      createdTime: "",
      orderLineStatus: 0,
      blockLoading: true,
      order: {},
      disputeDescription: "",
      subtotal: 0,
      ratingSellCom: 0,
      ratingShipTime: 0,
      ratingProdQual: 0,
      ratingComment: "",
      selectedProduct: {},
      submitInq: false,
      dateGap: null,
      selectedObj: {},
      delivery_partners: {},
      outletDataView: [],
      shippingFee: 0,
      couponDiscount: 0,
      failText: "",
      failTextName: "",
      // dispute
      isDisabled: false,
      disputeProductName: "",
      disputeMainOptions: [],
      disputeSubOptions: [],
      banks: [],
      branch: [],
      fileUploadFormData: new FormData(),
      disputeObj: {
       	order_line_id: "",
        dispute_reason: "",
        sub_dispute_reason: "",
        received_product: true,
        refund_method: 1,
        discribe_reason: "",
        account_number: "",
        // account_number: "21331",
        bank: "",
        branch: "",
        images:[]
      },
      bankId:"",
      image: [],
      isLoading: false,
      // dispute timer
      countdownseconds: 0,
      openDisputeTime: 0,
      countTime: 0,
      time: "",
      disableDsipue: true,
      selected:[],
      selectAll: "",
      selectpackId: ""
    };
  },
  computed: {
    oId() {
      let url = window.location.href.split("buyer-order-configure/");
      let newUrl = url[1];
      return newUrl;
    },
    // mId() {
    //   let url = window.location.href.split("buyer-order-configure/");
    //   let newUrl = url[1].split("/");
    //   return newUrl[0];
    // },
    commentLength() {
      let val = this.disputeDescription.length;
      return 150 - val;
    },
    formIsValid() {
      return (
        this.disputeObj.dispute_reason &&
        this.disputeObj.sub_dispute_reason
      );
    }
  },
  watch: {
    disputeDescription(val) {
      if (val.length <= 150) {
        this.disputeDescription = val;
      } else {
        this.disputeDescription = this.disputeObj.discribe_reason;
      }
    },
    countdownseconds: function(val) {
      var hour = Math.floor((val /3600));
      let remain1 = val % 3600
      var minutes = Math.floor((remain1/60));
      var seconds = Math.floor((remain1 % 60));
      this.countTime = hour + ":" + minutes + ":" + seconds;
      this.time = minutes + " min " + seconds + " secs ";
      if(val == 0){
          this.disableDsipue = true;
      } else {
          this.disableDsipue = false;
      }
      if(val==-1) {
          this.time = 0 + " min " + 0 + " secs ";
          this.disableDsipue = true;
      }

    }
  },
  created() {
    this.handleBreadcrumb();
    this.getOrdersDetails();
    // this.getOderSetting();
    this.getDisputeReasonMain();
    this.calcOpenDisputeTime()
    this.getBankList();
  },
  methods: {
    haddleFailedText: function(id, value) {
      if (id === 0) {
        this.failTextName = "Orders Cancelled by"
      } else if (id === 50) {
        this.failTextName = "Delivery Fail by"
      }
      this.failText = value;
      $("#failedTextModalCenter").modal("show");
    },
    orderListWeight(type) {
      switch (type) {
        default:
          return this.calculateWeight(this.oderLines);
          break;
      }
    },
    calculateWeight(obj) {
      var weight = 0;
      for (let index = 0; index < obj.length; index++) {
        const element = obj;

        if (
          element[index].simple_product_id.product_id.hasOwnProperty("package_weight")
        ) {
          var packweight = parseFloat(
            element[index].simple_product_id.product_id.package_weight
          ).toFixed(2);
          weight += Number(packweight) * element[[index]].qty;
        }
      }
      return parseFloat(weight).toFixed(2);
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    populateDeliveryPartners: function(responce) {
      
      this.delivery_partners = [];
      let dp_arraytxt = [];
      let dp_charges = [];

      this.orderLineStatus = responce.order_line_status;
      
      let delivery_partner = {
        company_name: responce.delivery_partner.company_name,
        mobile: responce.delivery_partner.mobile,
        email: responce.delivery_partner.email,
        _id: responce.delivery_partner._id
      };

      dp_charges.push({
        _id: element.delivery_partner._id,
        charge: element.delivery_charge
      });

      var uniquedp = dp_arraytxt.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });
      
      for (let index = 0; index < uniquedp.length; index++) {
        const element = uniquedp[index];
        let del_charge = 0;
        let obj = JSON.parse(element);
        dp_charges.forEach(element => {
          if (element._id == obj._id) {
            del_charge = element.charge + del_charge;
          }
        });
        obj.charge = del_charge;
        this.delivery_partners.push(obj);
      }
    },
    getOrdersDetails: async function() {
      try {
        this.blockLoading = true;
        let responceNew = await this.getSingleOrderDetailsMyMearchat(this.oId);
        // let responceNew = await this.getSingleOrderDetailsMyMearchat(this.mId, this.oId);
        let responce = responceNew[0];
        this.oderLines = responceNew;
        let ObjMap ={};

        responceNew.forEach(element => {
          var makeKey = element.merchant_id._id;
          if(!ObjMap[makeKey]) {
            ObjMap[makeKey] = [];
          }
          ObjMap[makeKey].push(element);
        });
        this.packageList = Object.entries(ObjMap);
        // console.log(this.packageList);

        if (responce.admin_approve === false && responce.order_line_status === 0) {
          this.submitInq = false;
          this.specialCondition = 1;
        }

        this.order = responce;
        this.tableItems = responce.simple_product_id;

        for (let index = 0; index < this.oderLines.length; index++) {
          const element = this.oderLines[index];
          this.couponDiscount += element.coupon_discount_amount
          
        }

        // this.couponDiscount = this.order.coupon_discount_amount;
        

        // Check duplicate outlet data & fetch
        var displayOutlets = [];
        var outlets = [];
        for (var i = 0, len = this.tableItems.length; i < len; i++) {
          if (
            outlets.indexOf(
              this.tableItems[i].simple_product_id.product_id.store._id
            ) > -1
          ) {
            // console.log('Duplicate type');
          } else {
            // console.log('New type');
            outlets.push(
              this.tableItems[i].simple_product_id.product_id.store._id
            );
            displayOutlets.push(this.tableItems[i]);
          }
        }
        if (this.order.payment_type == 4) {
          this.disputeRefundMethod = [
            { 
              _id: 1,
              method: "Bank Refund",
              active: true
            },
            { 
              _id: 2,
              method: "Voucher",
              active: true
            },
            { 
              _id: 3,
              method: "Credit Card Reversal",
              active: false
            }
          ]
          this.disputeObj.refund_method = 3;
        }
        this.outletDataView = displayOutlets;
        this.createdTime = moment(this.oderLines[0].created_date).format("hh:mm A");

        this.blockLoading = false;
        // this.populateDeliveryPartners(responce);
        
        this.calculateSubtotal(this.oderLines);
        this.calculateShippingFee(this.oderLines);
      } catch (error) {
        throw error;
      }
    },
    calculateSubtotal: function(responce) {

      this.subtotal = 0

      let objArray = responce.filter(el => {
          return el.order_line_status !== 0;
        });

      objArray.forEach(item => {
        this.subtotal = (item.unit_price * item.qty) + this.subtotal;
      });
    },
    calculateShippingFee: function(responce) {

      this.shippingFee = 0

      let objArray = responce.filter(el => {
          return el.order_line_status !== 0;
        });

      objArray.forEach(item => {
        this.shippingFee = item.delivery_charge + this.shippingFee;
      });
      
    },
    trackOrder: function(payload) {
      this.showTrack = false;
      this.selectedObj = {};
      this.selectedObj = payload;
      this.showTrack = true;
      // console.log("trackOrder",payload);
      $("#trackModalCenter").modal("show");
    },
    reviewProduct: function(payload) {
      this.selectedProduct = payload;
    },
    clearImage(index){ 
      this.image.splice(index, 1);
      document.getElementById("embedpollfileinput").value = "";
      this.fileUploadFormData = new FormData();
    },
    getBankList: async function() {
      try {
        let response = await this.getBankListInfo();
        this.banks = response;
      } catch (error) {
        console.log(error);
      }
    },
    changeBank: async function(event) {
      try {
        this.disputeObj.bank = event;
        let bId ;
        this.banks.forEach(element => {
          if (element.name === this.disputeObj.bank) {
            bId = element._id;
          }
        });
        let response = await this.getBranchListInfo(bId);
        this.branch = response;
      } catch (error) {
        console.log(error);
      }
    },
    changeBranch: function(event) {
      this.disputeObj.branch = event;
    },
    getFile: async function(eventf) {
      this.isLoading = true;
      await this.fileUploadFormData.append("image", eventf.target.files[0]);

      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          // self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putDisputeImage(formData); 
        this.image.push(response.image);
        this.isLoading = false;
        this.fileUploadFormData = new FormData();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    submitDispute: async function() {
      let submitValid = true;
      this.disputeObj.images = this.image;
      this.disputeObj.discribe_reason = this.disputeDescription;
      if (this.disputeObj.refund_method === 1 && this.order.payment_type !== 4) {
        if (this.disputeObj.bank === "" || this.disputeObj.branch === "" || this.disputeObj.account_number === "" ) {
          submitValid = false;
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please enter Bank Account Details!",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      if (this.order.payment_type === 4) {
        this.disputeObj.refund_method = 3 ;
      }
      if (submitValid) {
        try {
          let response = await this.postCreateDispute(this.disputeObj);
  
          if (response) {
            this.disputeObj.discribe_reason = "";
            this.disputeDescription = "";
            $("#disputeModalCenter").modal("hide");
            this.getOrdersDetails();
            this.$swal.fire({
              position: "center",
              type: "success",
              title: "Dispute created!",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (error) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Fail to submit dispute!",
            showConfirmButton: false,
            timer: 1500
          });
        }
        
      }
    },
    selectAccount: function(obj,packId) {
      if (typeof obj  !== 'object') {
        if (typeof obj  === 'string') {
          if (obj !== this.selectAll) {
            this.selected = [];
            this.packageList = this.packageList.map((item)  => {
              if (item[0] === obj ) {
                  for (let index = 0; index < item[1].length; index++) {
                    const element = item[1][index];
                    if (element.order_line_status === 1) {
                      this.selected.push(element._id);
                    }
                    
                  }
                  
                }
              return item;
            });
            this.selectpackId = packId;
            this.selectAll = obj;
          } else {
            this.selected = [];
            this.selectAll = "";
            this.selectpackId = "";
          }
        } else {
          this.selected = [];
          this.selectAll = "";
          this.selectpackId = "";
        }
      } else {
        if (this.selectAll !== "") {
          this.selected = [];
          this.selected.push(obj._id)
        }
        if (this.selectpackId !== "") {
          if (this.selectpackId !== packId) {
            this.selected = [];
            this.selected.push(obj._id);
            this.selectpackId = packId;
          }
        } else {
          this.selectpackId = packId;
        }
        this.selectAll = "";
        if (this.selected.length === 0) {
          this.selectpackId = "";
        }
      }
    },
    removeOrderItems: async function() {
      if (this.selected != [] ) {
        let obj = {};
        obj.line_ids = this.selected;
        obj.order_env_number = this.order.order_env_number;
        let self = this;
        this.$swal.fire({
          title: "If you want remove Selected Items?",
          text: "",
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            self.itemCancelSubmit(obj);
          }
        })
      } else {
        this.$swal.fire({
          position: "center",
            type: "error",
            title: "error!",
            showConfirmButton: false,
            timer: 1500
          });
      }
    },
    itemCancelSubmit: async function(obj) {
      try {
        let res = await this.putCancelOrder(obj);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "success!",
          showConfirmButton: false,
          timer: 1500
        });
        this.getOrdersDetails();
      } catch (error) {
        this.$swal.fire({
            position: "center",
            type: "error",
            title: "error!",
            showConfirmButton: false,
            timer: 1500
          });
      }
    },
    submitRating: async function() {
      try {
        let norating = true;
        let nocomment = true;
        if (
          this.ratingSellCom == 0 &&
          this.ratingShipTime == 0 &&
          this.ratingProdQual == 0
        ) {
          norating = true;
        } else {
          norating = false;
        }

        if (this.ratingComment === "") {
          nocomment = true;
        } else {
          nocomment = false;
        }

        if (nocomment && norating) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Comment or Rate!",
            showConfirmButton: false,
            timer: 1500
          });
          return;
        }

        let obj = {
          product: this.selectedProduct.product_id,
          seller_rating: this.ratingSellCom,
          shipping_rating: this.ratingShipTime,
          product_quality_rating: this.ratingProdQual,
          comment: this.ratingComment,
          images: [],
          order_id: this.oid,
          thumbs_up: false
        };

        let response = await this.postRatingReview(obj);
        $("#ratingModalCenter").modal("hide");
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Thank You!",
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        throw error;
      }
    },
    onClickRatingSellCom: function(val, type) {
      this.ratingSellCom = val;
    },
    onClickRatingShipTime: function(val, type) {
      this.ratingShipTime = val;
    },
    onClickRatingProdQual: function(val, type) {
      this.ratingProdQual = val;
    },
    // getOderSetting: async function() {
    //   try {
    //     let res = await this.getOderSettingListB();
    //     console.log("res")
    //     console.log(res)
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    haddlePassObj: function(obj) {
      $("#trackModalCenter").modal("hide");
      this.disputeProductName = obj.product_name;
      this.disputeObj.order_line_id = obj._id; 
    },
    getDisputeReasonMain: async function() {
      try {
        let res = await this.getDisputeReasonMainList();
        this.disputeMainOptions = res;
      } catch (error) {
        throw error;
      }
    },
    changeDisputeReason: async function(event) {
      try {
        this.disputeSubOptions = [];
        this.isDisabled = false;
        let res = await this.getDisputeReasonSubList(event);
        this.disputeSubOptions = res;
        this.isDisabled = true;
      } catch (error) {
        throw error;
      }
    },
    calcOpenDisputeTime: async function(obj) {
      try {
        let res = await this.getOderSettingListB();
        res.dispute_time += 1;

        let disputeOpenIn = res.dispute_time *3600;
        this.openDisputeTime = res.dispute_time;
        this.countdownCal(disputeOpenIn);

      } catch (error) {
        throw error;
      }
      // return obj.updated_date;
    },
    countdownCal(duration){
        this.countdownseconds = duration;
       let self = this;
        var downloadTimer = setInterval(function(){
            self.countdownseconds -= 1;
            if(self.countdownseconds <= 0){
                clearInterval(downloadTimer);
            }
        }, 1000);
    },
    calcDisputeTime(dispute0penTime, updateDate) {

      var now  =  moment(new Date).local().format('L, H:mm:ss a');
      var then =  moment(updateDate).local().format('L, H:mm:ss a');

      var ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
     
      var d = moment.duration(ms);
      var difrent = Math.floor(d.asHours()) + moment(ms).format(":mm:ss");

      // calculate dispute End Time
      var newDif = moment(dispute0penTime,"HH:mm:ss").diff(moment(difrent,"HH:mm:ss"));

      let newTime;
      if (0 < newDif) {
        var newD = moment.duration(newDif);
        var newDifrent = Math.floor(newD.asHours()) + moment(newDif).format(":mm:ss");
        let dif = newDifrent.split(":");

        newTime = dif[0] + " hours ";
        // newTime = dif[0] + " hours "+ dif[1] + " min " + dif[2] + " secs ";
        if(dif[0] === 0){
            this.disableDsipue = true;
        } else {
            this.disableDsipue = false;
        }
      } else {
        newTime = "0  hours";
        this.disableDsipue = true;
      }
      return newTime;
    }
  }
};
</script>
<style scoped>
#orderListBuyer {
  position: relative;
  /* margin-top: 15px; */
}
#orderListBuyer .vdp-datepicker input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
#orderListBuyer .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
#orderListBuyer .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #ffd338;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.dispute-text-input {
  width: 100%;
  padding-left: 10px;
  box-shadow: none;
  font-size: 14px;
  border: 1px solid lightgray;
  height: 34px;
}

.dispute-text-input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

#orderListBuyer .sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
#orderListBuyer .sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
#orderListBuyer .order-list-top {
  padding-top: 10px;
}
#orderListBuyer .search-prd-block {
  text-align: right;
}
#orderListBuyer .search-prd-block input[type="text"] {
  display: inline-block;
  width: 50%;
}
#orderListBuyer .filter-block select {
  display: inline-block;
  width: 75%;
  height: 35px !important;
}
#orderListBuyer .filter-block button {
  float: right;
}
#orderListBuyer .from-to-block {
  padding-top: 15px;
}
#orderListBuyer .pagination {
  margin-right: 20px;
}
.buyerodrlist .buyerprof-sbtn {
  background:#5779ae;
  color: #ffffff;
  border: none;
}
.buyerodrlist .buyerprof-sbtn:hover {
  background:#5779ae;
  color: #000000;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.buyertotal-value--tbl{
  width: 100% !important;
}



@media screen and (max-width: 1280px) {
  table {
    width: 960px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
  .buyerodr--singleview{
    max-width: 1000px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1200px) {
  #orderListBuyer .search-prd-block input[type="text"] {
    width: 100%;
  }
  #orderListBuyer .filter-block select {
    width: 90%;
  }
}
@media screen and (max-width: 725px) {
  #orderListBuyer .filter-block select {
    width: 80%;
  }
}
@media screen and (max-width: 625px) {
  #orderListBuyer {
    border: none !important;
  }
}
@media screen and (max-width: 560px) {
  #orderListBuyer .from-block,
  .to-block {
    width: 100%;
  }
  #orderListBuyer .from-to-block .dp-adjust {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #orderListBuyer .filter-block select {
    width: 70%;
  }
}
.align-line-spc-btn {
  display: flex;
  justify-content: space-between;
}
.bottom-form1 {
  background-color: #efefef;
  margin: 15px -10px 15px -10px;
}
.bordered {
  border: 1px solid #ddd;
}
.font-detail {
  font-size: 12px;
  font-weight: 400;
}
.btn-review {
  background-color: #17d424;
  color: white;
  margin-right: 5px;
  border-radius: 4px;
}
.btn-review:hover {
  background-color: #1bd028;
  color: white;
}
.btn-dispute {
  background-color: #ffa300;
  color: white;
  border: none;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 4px;
}

.btn-Remove {
  background-color: red;
  color: white;
  border: none;
  margin-left: 10px;
  margin-right: 5px;
  border-radius: 4px;
}

.merchprof-send-to-admin-sbtn {
  background:#ffa300;
  color: white;
  border: none;
}
.merchprof-send-to-admin-sbtn:hover {
  color: #000000;
}
.btn-dispute:hover {
  background-color: #ffa300;
  color: #000000;
}
.btn-track {
  background-color: #c4c4c4;
  color: white;
  border-radius: 4px;
}
.btn-track:hover {
  background-color: #c4c4c498;
  color: white;
}
.action-btn-set {
  min-width: 116px;
  height: 80px;
}
.box-modal {
  border-radius: 5px;
  background-color: #efefef;
  margin: 0px;
  padding: 10px;
  margin-bottom: 10px;
}
.char-comment {
  position: absolute;
  right: 4%;
  bottom: 0%;
}
</style>
